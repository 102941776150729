import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CONFIG_MANAGER_SERVICE_CONFIGURATION, ConfigManagerModule, ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import { XpoShellModule } from '@xpo-ltl/ngx-ltl-core/shell';
import { XPO_AUTH_CONFIG, XpoAuthConfig } from '@xpo/ngx-auth';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SCHEDULE_OPERATIONS_API_BASE_PATH } from './injection-tokens';
import { MaterialModule } from './material.module';

export function authConfigFactory(
  configService: XpoLtlAuthConfigLoaderService
): XpoAuthConfig {
  configService.initSettings('./assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri,
  });
}

export function buildApiUrl(config: ConfigManagerService, uri: string): string {
  let apiBase: string = config.getSetting('apiUrl');

  if (apiBase.substr(-1) !== '/') {
    apiBase += '/';
  }

  return apiBase + (uri.charAt(0) === '/' ? uri.substr(1) : uri);
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule,
    BrowserAnimationsModule,
    ConfigManagerModule,
    HttpClientModule,
    MaterialModule,
    XpoShellModule,
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
    AppRoutingModule
  ],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
    {
      provide: SCHEDULE_OPERATIONS_API_BASE_PATH,
      useFactory: (config: ConfigManagerService) => {
        return buildApiUrl(config, 'opslinehaul/1.0');
      },
      deps: [ConfigManagerService],
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
