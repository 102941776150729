<xpo-shell [routes]="routes" menuClosedOnDefault="true" [drawerClosedByDefault]="true" [versionNumber]="'1'">
  <xpo-header-app-name>Edge</xpo-header-app-name>
  <xpo-header-sub-app-name>{{ title }}</xpo-header-sub-app-name>
  <div class="app-container">
    <div class="app-container-content">
      <div class="app-container-content-inner-content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <div class="footer">
  </div>
</xpo-shell>
