import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { LicenseManager } from 'ag-grid-enterprise';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

LicenseManager.setLicenseKey(
  'CompanyName=XPO Logistics (Portland, OR 97209),LicensedGroup=xpo-ltl,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=45,LicensedProductionInstancesCount=0,AssetReference=AG-010153,ExpiryDate=7_October_2021_[v2]_MTYzMzU2MTIwMDAwMA==5929431bd3e2e336dd35e2d11cddb408'
);


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
