import { Component, Inject } from '@angular/core';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { XpoShellRoute } from '@xpo-ltl/ngx-ltl-core/shell';
import { SCHEDULE_OPERATIONS_API_BASE_PATH } from './injection-tokens';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [XpoLtlAuthenticationService ]
})
export class AppComponent {
  title = 'Ops Schedule';
  routes!: XpoShellRoute[];

  constructor(
    private authService: XpoLtlAuthenticationService,
    private configService: XpoLtlAuthConfigLoaderService,
    @Inject(SCHEDULE_OPERATIONS_API_BASE_PATH) private x: any,
  ) {
    this.configService.initSettings('./assets/config.json');

    const region = this.configService.region;
    this.authService.initAuthSetup$(region).subscribe();
  }
}
